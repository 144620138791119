import styled from 'styled-components';
 
export const Container = styled.section`
  height: 20vh;
  color: var(--color--primary);

  img {
    width: 30px;
    cursor: pointer;
  }
`;